import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./portal_service_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import CreateModal from './create_portal_service_modal';
import IconButton from '../../components/my-components/my_icon_button';
import EditModal from './edit_portal_service_modal';
import GroupBtn from '../../components/buttons/groupBtn';
import { add_text, delete_modal_text, delete_text, edit_text, service_classname_text, service_title_text, view_details_text } from '../../constant';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import ConfirmationModal from '../../components/my-components/confirmation-modal';
import { formatNumberWithCurrency } from '../../utils/formatters';
import XToggleButton from '../../components/my-components/xtoggle_button';


const PortalServiceView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, create, edit, Delete, find, totalElements} = useCrud({url: "portal/services", setLoading: setLoading, firstLoad:false})
    const [images,setImages] = useState([]);
    const [selectedRow, setSelectedRow] = useState({extrafields: []})

    const [filters, setFilters] = useState({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalData, setConfirmationModalData] = useState({
        description:'',
        onSubmit:()=>{},
        onCancel:()=>setIsConfirmationModalOpen(false)
    })

    const handleUpload = async (icon) => {
            if (!icon) {
              alert("Por favor selecciona un archivo primero.");
              return;
            }
            
            const formData = new FormData();
            formData.append("file", icon);
        
            try {
                setLoading(true)
        
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}files/upload`, {
                    method: "POST",
                    headers:{
                        //"Content-Type":"multipart/form-data",
                        "Authorization": `Bearer ${localStorage.getItem("userToken")}`
                    },
                    body: formData,
                    processData: false,
                    contentType: false,
                    cache:'no-cache'
                });

                setLoading(false)
        
              if (response.ok) {
                return await response.text();
              } else {
                toast.error("Error guardando icono");
                return ''
              }
            } catch (error) {
                setLoading(false)
                toast.error("Error guardando icono");
                return ''
            }
          };

        const fetchImage = async (fileName) => {
            try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}files/download/${fileName}`, {
                method: "GET",
                headers: {
                  "Content-Type": "image/*", // Asegúrate de que el backend envíe imágenes
                },
              });
      
              if (response.ok) {
                const blob = await response.blob(); // Convierte la respuesta a blob
                const imageUrl = URL.createObjectURL(blob); // Crea una URL para el blob
                return imageUrl; // Guarda la URL en el estado
              } else {
                console.error("Error al obtener la imagen");
              }
            } catch (error) {
              console.error("Error al conectarse al servidor:", error);
            }
        }
    
    const onCreate = async (model) => {
    

        let iconName = await handleUpload(model.icon)
        if(iconName == '')
            return

        create({...model,iconName: iconName, icon:null})
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setCreateModalIsOpen(false);
    }

    const onEdit = async (model) => {

        if(model.iconChange){
            let iconName = await handleUpload(model.icon)
            if(iconName != '')
                model = {...model, iconName:iconName}
            else
                return
        }
            
            edit(model)
          .catch(error => toast.error("Upps, there are some errors. Please, try again"))
      
            setEditModalIsOpen(false);
    }

    useEffect(()=>{
        find(filters)
    },[filters])

    const fetchAllImages = async () => {
        let images = []

        for(let i = 0; i<list.length; i++){
            let image = await fetchImage(list[i].iconName)
            images.push(image)
        }
        setImages(images)
    }

    useEffect(()=>{
        fetchAllImages()
    },[list])
    
   
    

    const columns = [
      {name: 'Id de Servicio',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Nombre',selector: (row) =>row.name,sortable: true,center:true,},
      {name: 'Resumen',selector: (row) => row.shortDescription,sortable: false,center:true},
      {name: 'Habilidado',minWidth: '150px',selector: (row) => row.active,sortable: false,center:true},
      {name: 'Icono',minWidth: '150px',selector: (row) => row.icon,sortable: false,center:true},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: false,center:true},

  ];

    const handleDelete = (id) => {
        setIsConfirmationModalOpen(true)
        setConfirmationModalData({
            ...confirmationModalData,
            description: `${delete_modal_text}${service_classname_text}?`,
            onSubmit: async ()=>{
                await Delete(id)
                setIsConfirmationModalOpen(false)
            }
        })
      
    };

    const updateServiceActive = async (service) => {
        service.disabled = !service.disabled;
        await edit(service);
        toast.success("Actualizado correctamente");
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    

    return (
        <div>
            <Breadcrumb title={service_title_text}/>
            <br/>
            <div style={{display:"flex"}}>
                <div className='btn-right-container'>
                    <Button className='btn btn-primary' color="primary" onClick={()=>{setCreateModalIsOpen(true)}}>{add_text}</Button>
                </div>
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map((row,i) => {
                    return {
                      ...row,
                      active: <XToggleButton checked={!row.disabled} onClick={()=>{updateServiceActive(row)}} />,
                      icon: <img src={images[i]} alt="" style={{ maxWidth: "100%" }} />,
                      actions: <div className='flex'>
                                    <IconButton icon={
                                        <span className='action-box large update-btn'>
                                            <i className='icon'>
                                                <i className='icon-pencil'></i>
                                            </i>
                                        </span>
                                        }
                                        text={edit_text}
                                        onClick={()=>{setSelectedRow(row);setEditModalIsOpen(true); }}
                                        color="warning"
                                    />
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            
            
            <CreateModal 
                isVisible= {createModalIsOpen} 
                onCreate={onCreate} 
                onCancel= {()=>{setCreateModalIsOpen(false)}}
            />

            <EditModal 
                isVisible= {editModalIsOpen} 
                onEdit={onEdit} 
                onCancel= {()=>{setEditModalIsOpen(false)}}
                pages={[]}
                oldRow={selectedRow}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
            <ConfirmationModal 
                isVisible={isConfirmationModalOpen} 
                description={confirmationModalData.description} 
                onSubmit={confirmationModalData.onSubmit} 
                onCancel={confirmationModalData.onCancel}
                />
        </div>
      
      )
}

export default PortalServiceView;