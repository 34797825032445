import React, { Fragment, useState, useEffect } from "react";
import seven from "../../assets/images/user/7.jpg";
import { MyProfile,Password, EditProfile, Username,  UpdateProfile, EmailAddress, FirstName, LastName, Phone, ChangePassword, user_profile_title } from "../../constant";
import { emailValidator, notRequiredValidator, orValidators, passwordValidator, phoneValidator, requiredDropdownValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators.js";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../components/my-components/loading_spinner.js";
import useCrud from "../../hooks/use_crud.js";
import Breadcrumb from "../../components/common/breadcrumb.js";
import DataTable from 'react-data-table-component'
import UsersView from "../users/users_view.js";

const UserDetails = () => {

  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const {findById, edit} = useCrud({url : 'auth', setLoading : setLoading, firstLoad: false})
  const [data, setData] = useState({roles:[{name:"none"}]});
  const [updateData, setUpdateData] = useState({roles:[{name:"none"}]});
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
  const {list: addresses, find, totalElements} = useCrud({url: "address", setLoading: setLoading, firstLoad:false})

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    findById(searchParams.get('id')).then(user => {
      setData(user)
      setUpdateData({
        ...user,
        name: user.name? user.name.replace(user.familyName,'').trimEnd(): ''
      })
    })
    
  }, [location]);


  const columns = [
    {name: 'Id de Dirección',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
    {name: 'Alias',selector: (row) =>row.alias,sortable: true,center:true,},
    {name: 'Colonia', minWidth: '170px',selector: (row) =>row.colony?row.colony.name:"",sortable: true,center:true,},
    {name: 'Municipio',minWidth: '170px',selector: (row) =>row.colony && row.colony.municipality?row.colony.municipality.name:"",sortable: true,center:true,},
    {name: 'Estado',minWidth: '170px',selector: (row) =>row.colony && row.colony.municipality && row.colony.municipality.state?row.colony.municipality.state.name:"",sortable: true,center:true,},
    {name: 'Tipo de dirección',minWidth: '170px',selector: (row) => row.addressType?row.addressType.name:"",sortable: true,center:true,}
];

const handlePageChange = page => {
    find({users: {id:data.id}},{size:10,page:page-1});
  };


const handlePerRowsChange = async (newPerPage, page) => {
    find({users: {id:data.id}},{size:newPerPage,page:page});

  };

  
  useEffect(()=>{
    if(data != null && (data.type == "CLIENT" || data.type == "ENTERPRISE"))
        find({users: {id:data.id}})
},[data])

  return (
    <Fragment>
      <br/>
      <div className="container-fluid">
        <div className="edit-profile">
          <Breadcrumb parent= "Usuarios" title={user_profile_title}/>
          <div className="row">
            <div className="">
              <form className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Detalles de Usuario</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{Username}</label>
                        <input disabled className="form-control" type="text" value={updateData.username} onChange={(evt)=> setUpdateData({...updateData,username:evt.target.value})} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label">{FirstName}</label>
                        <input disabled className="form-control" type="text" value={updateData.name} onChange={(evt)=> setUpdateData({...updateData,name:evt.target.value})} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label">{LastName}</label>
                        <input disabled className="form-control" type="text" value={updateData.familyName} onChange={(evt)=> setUpdateData({...updateData,familyName:evt.target.value})} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label">{Phone}</label>
                        <input disabled className="form-control" type="text" value={updateData.cellphone} onChange={(evt)=> setUpdateData({...updateData,cellphone:evt.target.value})} />
                      </div>
                    </div>
                  </div>
                  {
                    data != null && (data.type == "CLIENT" || data.type == "ENTERPRISE") &&
                  <div>
                    <br/>
                    <div className="card-header">
                        <h4 className="card-title mb-0">Direcciones Registradas</h4>
                    </div>
                    <DataTable
                            pagination 
                            paginationServer
                            paginationComponentOptions ={{
                                noRowsPerPage:true
                            }}
                            paginationTotalRows={totalElements} 
                            onChangeRowsPerPage={handlePerRowsChange} 
                            onChangePage={handlePageChange}
                            striped={true}
                            center={true}
                            columns={columns}
                            data={addresses}
                        />
                        </div>
                }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingSpinner isVisible={loading} />
    </Fragment>
  );
};

export default UserDetails;
