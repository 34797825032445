import { Home, Box, DollarSign, UserPlus, Users, Chrome, Settings, Airplay, FolderPlus, File, Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail, MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search, AlertOctagon, Lock, Briefcase, BarChart, Target, List, User, Archive } from "react-feather";

export const MENUITEMS = [
  /*{
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: "/dashboard/default",
    active: false,
  },*/
  {
    title: "Usuarios",
    icon: Users,
    type: "link",
    path: "/users",
    active: false,
    permissions: ["ADMIN"]
  },
  {
    title: "Roles",
    icon: Lock,
    type: "link",
    path: "/roles",
    active: false,
    permissions: ["ADMIN",]
  },
  /*
  {
  title: "Direcciones",
  icon: Map,
  type: "link",
  path: "/addresses",
  active: false,
  permissions: ["ADMIN"]
  },*/
  {
    title: "Órdenes",
    icon: DollarSign,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/orders", title: "Órdenes", type: "link", permissions: ["ADMIN","OPERATOR"]},
      { path: "/orders/ordertypes", title: "Tipos de Orden", type: "link", permissions: ["ADMIN"]},
    ],
    permissions: ["ADMIN","OPERATOR"]
  },
  {
    title: "Servicios",
    icon: DollarSign,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/services", title: "Servicios", type: "link", permissions: ["ADMIN"]},
      { path: "/services/requests", title: "Solicitudes de servicios", type: "link", permissions: ["ADMIN","OPERATOR"] },
    ],
    permissions: ["ADMIN","OPERATOR"]
  },

  {
    title: "Reportes",
    icon: Archive,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/orders/report", title: "Órdenes", type: "link", permissions: ["ADMIN","OPERATOR"]},
      { path: "/services/report", title: "Servicios", type: "link", permissions: ["ADMIN","OPERATOR"]},
    ],
    permissions: ["ADMIN","OPERATOR"]
  },

  //Payments
  {
    title: "Pagos",
    icon: DollarSign,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/payments/types", title: "Tipos", type: "link", permissions: ["ADMIN","OPERATOR"]},
      { path: "/payments", title: "Historial", type: "link", permissions: ["ADMIN","OPERATOR"]},
    ],
    permissions: ["ADMIN","OPERATOR"]
  },

  //Driver
  {
    title: "Órdenes",
    icon: DollarSign,
    type: "link",
    path: "/orders/driver",
    active: false,
    permissions: ["DRIVER"]
  },

  {
    title: "Portal",
    icon: DollarSign,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/portal/services", title: "Servicios", type: "link", permissions: ["ADMIN"]},
    ],
    permissions: ["ADMIN","OPERATOR"]
  },

];
