import { useEffect, useState } from "react";
import { portal_service_short_description_field_text, portal_service_long_description_field_text, edit_modal_submit_text, edit_modal_text, modal_cancel_text, service_classname_text, service_description_field_text, service_prize_field_text, service_name_field_text } from "../../constant";
import {  checklistRequiredValidator, notRequiredValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators";
import { toast } from "react-toastify";
import { File } from "react-feather";


const EditOrderTypeModal = ({isVisible, oldRow={}, onEdit, onCancel, }) => {


    const [newRow, setNewRow] = useState(oldRow)

    const validators = {
        name:requiredValidator('nombre'),
        shortDescription:requiredValidator('Resumen'),
        largeDescription:requiredValidator('Descripción'),
        icon:requiredValidator('Icono'),   
    }

    const onSubmit = () => {
        const validationResult = validateForm(newRow, validators)
        if(validationResult.isValid){
            onEdit(newRow)
        }
        else
            showFormErrors(validationResult, toast)
    } 

    useEffect(()=>{
        setNewRow(oldRow)
    },[oldRow])

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                    <div className="card-header">
                        <h5>{edit_modal_text+service_classname_text}</h5>
                    </div>
                    <div className="form theme-form">
                        <div className="card-body">
                             <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{service_name_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="text" value={newRow.name} onChange={(evt)=>setNewRow({...newRow,name:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>   
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{portal_service_short_description_field_text}</label>
                                        <textarea className="form-control" id="exampleFormControlInput20" value={newRow.shortDescription} onChange={(evt)=>setNewRow({...newRow,shortDescription:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>   
                            <div className="file-input-container">
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="file-upload"
                                    onChange={(evt) => setNewRow({...newRow,iconChange:true,icon:evt.target.files[0]})}
                                    className="hidden-input"
                                />
                                <label htmlFor="file-upload" className="file-label">
                                    <File className="file-icon"/>
                                    <span>{newRow.iconChange?"Icono seleccionado":"Selecciona un icono"}</span>
                                </label>
                            </div>               
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{portal_service_long_description_field_text}</label>
                                        <textarea className="form-control" id="exampleFormControlInput20" value={newRow.largeDescription} onChange={(evt)=>setNewRow({...newRow,largeDescription:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                        
                        <div className="card-footer text-end">
                            <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(); }} >{edit_modal_submit_text}</button>
                            <button className="btn btn-light" type="reset" onClick={()=>{ onCancel();}} >{modal_cancel_text}</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )
}

export default EditOrderTypeModal;