import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import { create_modal_submit_text, create_modal_text, modal_cancel_text, service_classname_text, service_description_field_text, service_prize_field_text, service_name_field_text, portal_service_short_description_field_text, portal_service_long_description_field_text } from "../../constant";
import { checklistRequiredValidator, notRequiredValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators";
import { toast } from "react-toastify";
import Dropbox from "../../components/my-components/dropbox";
import { File } from "react-feather";


const CreateServiceModal = ({isVisible, onCreate, onCancel, pages = []}) => {

    const defaultModel = {
        name:"",
        shortDescription:"",
        longDescription:"",
        iconName:"",
        icon:"",
        disabled:false
    }

    const [model, setModel] = useState(defaultModel)
    const [icon, setIcon] = useState(null)

    const reset = ()=>{
        setModel({
            name:"",
            shortDescription:"",
            largeDescription:"",
            icon:"",
            disabled:false
        })
    }

    const validators = {
            name:requiredValidator('nombre'),
            shortDescription:requiredValidator('Resumen'),
            largeDescription:requiredValidator('Descripción'),
            icon:requiredValidator('Icono'),    
    }

    

    const onSubmit = async () => {
        const validationResult = validateForm(model, validators)
        
        
        if(validationResult.isValid){
            onCreate(model)
        }
        else
            showFormErrors(validationResult, toast)
    } 
      

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{create_modal_text+service_classname_text}</h5>
                </div>
                <div className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{service_name_field_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="text" value={model.name} onChange={(evt)=>setModel({...model,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{portal_service_short_description_field_text}</label>
                                    <textarea className="form-control" id="exampleFormControlInput20" value={model.shortDescription} onChange={(evt)=>setModel({...model,shortDescription:evt.target.value})}/>
                                </div>
                            </div>
                        </div>    
                        <div className="file-input-container">
                            <input
                                type="file"
                                accept="image/*"
                                id="file-upload"
                                onChange={(evt) => setModel({...model,icon:evt.target.files[0]})}
                                className="hidden-input"
                            />
                            <label htmlFor="file-upload" className="file-label">
                                <File className="file-icon"/>
                                <span>{model.icon != ""?"Icono seleccionado":"Selecciona un icono"}</span>
                            </label>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{portal_service_long_description_field_text}</label>
                                    <textarea className="form-control" id="exampleFormControlInput20" value={model.largeDescription} onChange={(evt)=>setModel({...model,largeDescription:evt.target.value})}/>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(); reset();}} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
    )
}

export default CreateServiceModal;